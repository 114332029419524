<template>
  <portal to="app-extension">
    <v-toolbar
      flat
      dense
      :color="$vuetify.theme.dark ? '#121212': ''"
    >
      <template v-if="!isMobile">
        <span class="title ml-2" v-if="calendarRef">
          {{ calendarRef.title }}
        </span>
      </template>
      <span class="title">
        {{ date }}
      </span>
      <v-spacer></v-spacer>
      <v-menu bottom right>
      </v-menu>
      <v-badge
        overlap
        bordered
        color="primary"
        icon="mdi-check-outline"
        :value="showBadge"
      >
        <v-btn
          small
          outlined
          color="primary"
          class="text-none"
          @click="toggleDrawer(true)"
        >
          <v-icon small left>mdi-filter-variant</v-icon>
          {{ $t('alerts.filters') }}
        </v-btn>
      </v-badge>
    </v-toolbar>
  </portal>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'AlertToolbar',
  data() {
    return {
      typeToLabel: {
        default: 'list',
        month: 'month',
        week: 'week',
        day: 'day',
      },
    };
  },
  computed: {
    ...mapState('alerts', [
      'drawer',
      'view',
      'today',
      'calendarRef',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('helper', ['locale']),
    viewType: {
      get() {
        return this.view;
      },
      set(view) {
        this.setView(view);
      },
    },
    showBadge() {
      return true;
    },
    date() {
      let dateText = '';
      if (this.filters && this.filters.date && this.filters.date.value) {
        const start = formatDate(
          new Date(this.filters.date.value[0]),
          'PP',
          { locale: this.locale },
        );
        dateText = start;
        let end = '';
        if (this.filters.date.value.length === 2) {
          end = formatDate(
            new Date(this.filters.date.value[1]),
            'PP',
            { locale: this.locale },
          );
        }
        dateText = `${start} - ${end}`;
      }
      return dateText;
    },
  },
  methods: {
    ...mapMutations('alerts', [
      'toggleDrawer',
      'setView',
      'setCalendarFocus',
    ]),
  },
};
</script>
