<template>
  <v-data-table
    :items="items"
    :headers="headers"
    hide-default-footer
    disable-pagination
    item-key="ontime"
  >
    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <alert-status-template :status="item.status" />
    </template>
  </v-data-table>
</template>

<script>
import AlertStatusTemplate from './AlertStatusTemplate.vue';

export default {
  name: 'AlertView',
  components: {
    AlertStatusTemplate,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    columnHeaders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [],
    };
  },
  async created() {
    if (this.columnHeaders) {
      this.headers = this.columnHeaders.map((col) => ({
        text: col.description,
        value: col.name,
      })).filter((da) => da.value !== 'customerid'
        && da.value !== 'smsalerttext' && da.value !== 'pushalerttext'
        && da.value !== 'emailalerttext');
    }
  },
};
</script>
