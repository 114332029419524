<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-avatar
        size="16"
        class="mb-1"
        v-bind="attrs"
        v-on="on"
        :color="getAlertStatus(status).color"
      ></v-avatar>
    </template>
    {{ $t(`alerts.${getAlertStatus(status).text}`) }}
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AlertStatusTemplate',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('alerts', ['alertStatus']),
  },
  methods: {
    getAlertStatus(status) {
      return this.alertStatus(status);
    },
  },
};
</script>
