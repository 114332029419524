<template>
  <div style="height:100%">
    <template v-if="loading">
      <alert-loading />
    </template>
    <template v-else-if="error">
      <alert-error />
    </template>
    <template v-else-if="!error && !alertData">
      <alert-no-records />
    </template>
    <template v-else>
      <alert-view :items="filterAlertList" :columnHeaders="alertData.cols" />
    </template>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import AlertLoading from '../AlertLoading.vue';
import AlertError from '../AlertError.vue';
import AlertNoRecords from '../AlertNoRecords.vue';
import AlertView from '../data-table/AlertView.vue';

export default {
  name: 'AlertList',
  data() {
    return {
      filterAlertList: [],
    };
  },
  components: {
    AlertLoading,
    AlertError,
    AlertNoRecords,
    AlertView,
  },
  computed: {
    ...mapState('alerts', [
      'loading',
      'error',
      'alertData',
    ]),
    ...mapGetters('alerts', ['alertStatus']),
    ...mapGetters('webApp', ['group', 'filters']),
    status() {
      return this.filters.status.value;
    },
  },
  methods: {
    ...mapActions('alerts', ['getAlertReportData']),
    getAlertStatus(status) {
      return this.alertStatus(status);
    },
    updateStatus() {
      if (this.alertData && this.alertData.reportData && this.alertData.reportData.length) {
        // eslint-disable-next-line
        const filterData = this.alertData.reportData.filter((alert) => this.status.includes(alert.status));
        this.filterAlertList = filterData;
      } else if (this.alertData && this.alertData.reportData) {
        this.filterAlertList = [];
      }
    },
  },
  async created() {
    await this.getAlertReportData();
    this.updateStatus();
  },
  watch: {
    status() {
      this.updateStatus();
    },
    alertData() {
      this.updateStatus();
    },
  },
};
</script>
