<template>
  <div style="height:100%">
    <portal to="app-header">
      <span>{{ $t('alerts.title') }}</span>
      <v-btn icon small class="ml-4 mb-1">
        <v-icon
          v-text="'$info'"
        ></v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            class="ml-2 mb-1"
            @click="refreshAlerts"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        {{ $t('alerts.lastRefreshed') }} <strong>{{ lastRefreshedAt }}</strong>
      </v-tooltip>
    </portal>
    <alert-toolbar />
    <alert-drawer />
    <v-progress-linear
      :indeterminate="true"
      v-if="loading"
    ></v-progress-linear>
    <component :is="component" />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  // mapGetters,
} from 'vuex';
import AlertToolbar from '../components/AlertToolbar.vue';
import AlertDrawer from '../components/AlertDrawer.vue';
import AlertList from '../components/views/AlertList.vue';

export default {
  name: 'Alerts',
  components: {
    AlertToolbar,
    AlertDrawer,
    AlertList,
  },
  computed: {
    ...mapState('alerts', ['loading', 'lastRefreshedAt']),
    component() {
      return 'alert-list';
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  created() {
    this.setExtendedHeader(true);
  },
  methods: {
    ...mapMutations('helper', ['setExtendedHeader']),
    ...mapActions('alerts', ['getAlertReportData']),
    refreshAlerts() {
      this.getAlertReportData();
    },
  },
};
</script>
