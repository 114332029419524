<template>
  <div style="height: 100%">
    <alert-loading v-if="loading" />
    <template v-else>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import AlertLoading from './AlertLoading.vue';

export default {
  name: 'AlertsIndex',
  components: {
    AlertLoading,
  },
  data() {
    return {
      loading: false,
      timeout: null,
    };
  },
  computed: {
    ...mapState('alerts', [
      'alertData',
      'selectedPlan',
    ]),
    ...mapState('webApp', ['config', 'storageLocation']),
  },
  async created() {
    this.loading = true;
    const config = localStorage.getItem(this.storageLocation.planning);
    if (config) {
      this.setConfig(JSON.parse(config));
    } else {
      this.resetConfig();
    }
    const appId = localStorage.getItem('appId');
    await this.getAppSchema(appId);
    await this.fetchShifts();
    this.loading = false;
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapMutations('alerts', [
      'setPlan',
      'setPlanView',
    ]),
    ...mapActions('webApp', ['getAppSchema']),
    ...mapActions('alerts', [
      'getOnboardingState',
      'fetchShifts',
      'getScheduledEnd',
    ]),
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        localStorage.setItem(this.storageLocation.planning, JSON.stringify(val));
      },
    },
    async onboarded(val) {
      if (val) {
        await Promise.all([
          this.fetchShifts(),
        ]);
      }
    },
  },
};
</script>
